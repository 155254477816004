body {
  background-color: aqua;
}
.container {
  margin: auto;
  max-width: 600px;
}

.done {
  text-decoration: line-through;
}

.task-button {
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

/* .task-button:hover{
    background-color: grey;
} */

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 30px;
  padding: 15px;
  margin-top: 10px;
}

.card {
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.2);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addButton {
  cursor: pointer;
  width: 50px;
  height: 50px;
  font-size: 20px;
  border: none;
  background-color: skyblue;
  border-radius: 25px;
}

.modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hide {
  display: none;
}

.card-modal {
  padding: 20px;
  background-color: white;
}

.add-item-input {
  border: none;
  margin-right: 15px;
  padding: 10px;
  height: 30px;
  width: 300px;
  font-size: 22px;
  box-shadow: 0 2px 7px 2px rgba(0, 0, 0, 0.2);
}

.add-item-input:focus {
  outline: none;
}

.add-item-button {
  cursor: pointer;
  width: 50px;
  height: 50px;
  font-size: 20px;
  border: none;
  background-color: skyblue;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .container {
   width: 96%; 
  }

}
